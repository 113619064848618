const localization: any = {
  en: {
    tools_actions: {
      hotel_info: "Looking for hotel information...",
      generate_quote: "Preparing a quote...",
      check_availability: "Checking availability...",
      print_calendar: "Checking calendar...",
      nearby_restaurants: "Looking for nearby restaurants...",
      nearby_attractions: "Looking for nearby attractions...",
      human_handoff: "Handing off to an operator...",
    },
    tooltip_sentences: [
      "Need a quick quote? 😊",
      "Need information about the hotel? 😊",
    ],
    replies_in_realtime: "Replies in real-time",
    powered_by: "Powered by",
    type_a_message: "Type a message...",
    speak_with_operator: "Speak with an operator",
    handoff_form: {
      title: "Contact us",
      desc: "Enter your details and we will contact you via email.",
      firstname: "First Name",
      lastname: "Last Name",
      email: "Email",
      submit: "Submit Request",
      success:
        "Thank you for submitting the request, we will contact you at the provided email address as soon as possible! 😊",
      firstname_error: "Please enter your first name.",
      lastname_error: "Please enter your last name.",
      email_error: "Please enter your email address.",
    },
  },
  it: {
    tools_actions: {
      hotel_info: "Cercando informazioni sull'hotel...",
      generate_quote: "Preparando un preventivo...",
      check_availability: "Controllo disponibilità...",
      print_calendar: "Controllo calendario...",
      nearby_restaurants: "Cercando ristoranti nelle vicinanze...",
      nearby_attractions: "Cercando attrazioni nelle vicinanze...",
      human_handoff: "Collegamento con un operatore...",
    },
    tooltip_sentences: [
      "Vuoi un preventivo veloce? 😊",
      "Vuoi informazioni sull'hotel? 😊",
    ],
    replies_in_realtime: "Risposte in tempo reale",
    powered_by: "Sviluppato da",
    type_a_message: "Scrivi un messaggio...",
    speak_with_operator: "Parla con un operatore",
    handoff_form: {
      title: "Contattaci",
      desc: "Inserisci i tuoi dati e ti contatteremo via email.",
      firstname: "Nome",
      lastname: "Cognome",
      email: "Email",
      submit: "Invia Richiesta",
      success:
        "Grazie per aver inviato la richiesta, ti contatteremo all'indirizzo email fornito il prima possibile! 😊",
      firstname_error: "Inserisci il tuo nome.",
      lastname_error: "Inserisci il tuo cognome.",
      email_error: "Inserisci il tuo indirizzo email.",
    },
  },
  get: function (language: string) {
    switch (language) {
      case "it":
        return this.it;
      default:
        return this.en;
    }
  },
};

export default localization;
