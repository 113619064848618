import { useState } from "react";
import ChatService from "../../../../front-services/ChatService";
import Utility from "../../logic/Utility";
import ChatCache from "../../../../utils/ChatCache";
import localization from "../../../../assets/localization";

const chatService = new ChatService();
const chatCache = new ChatCache();

export default function HandoffForm({
  color,
  setIsHandoffFormFullScreen,
  threadId,
  assistantId,
  setMessages,
  setShowButton,
  language,
}: any) {
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (event: any) => {
    setLoading(true);
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const formValues = {
      firstName: formData.get("first-name"),
      lastName: formData.get("last-name"),
      email: formData.get("email"),
    };

    const userMessage = `Nome: ${formValues.firstName}, Cognome: ${formValues.lastName}, Email: ${formValues.email}`;

    chatService
      .setRedirectedStatus({
        threadId,
        assistantId,
        redirectedAt: "NEW",
      })
      .then(() => {
        chatService
          .sendMessageWithoutReply(userMessage, assistantId, threadId)
          .then(() => {
            setShowButton(false);
            setIsHandoffFormFullScreen(false);
            setLoading(false);
            let confirmationMessage = {
              type: "bot",
              content: localization.get(language).contact_us.success,
              time: Utility.getTime(),
            };
            setMessages((prevMessages: any) => [
              ...prevMessages,
              confirmationMessage,
            ]);
            chatCache.addMessage(confirmationMessage, threadId, assistantId);
          });
      });
  };

  return (
    <div className="handoff-form-container">
      <button
        className="handoff-minimize-button"
        style={{ backgroundColor: color }}
        onClick={() => setIsHandoffFormFullScreen(false)}
      >
        <i className="fa-solid fa-x"></i>
      </button>
      <div className="handoff-form-header">
        <h2 style={{ marginBottom: "1rem" }}>
          {localization.get(language).handoff_form.title}
        </h2>
        <p>{localization.get(language).handoff_form.desc}</p>
      </div>

      <form className="handoff-form-form" onSubmit={handleFormSubmit}>
        <div className="handoff-form-input">
          <input
            type="text"
            placeholder={localization.get(language).handoff_form.firstname}
            name="first-name"
            required
            onInvalid={(e) =>
              (e.target as HTMLInputElement).setCustomValidity(
                localization.get(language).handoff_form.firstname_error
              )
            }
            onInput={(e) =>
              (e.target as HTMLInputElement).setCustomValidity("")
            }
          />
        </div>
        <div className="handoff-form-input">
          <input
            type="text"
            placeholder={localization.get(language).handoff_form.lastname}
            name="last-name"
            required
            onInvalid={(e) =>
              (e.target as HTMLInputElement).setCustomValidity(
                localization.get(language).handoff_form.lastname_error
              )
            }
            onInput={(e) =>
              (e.target as HTMLInputElement).setCustomValidity("")
            }
          />
        </div>
        <div className="handoff-form-input">
          <input
            type="email"
            placeholder={localization.get(language).handoff_form.email}
            name="email"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            onInvalid={(e) => {
              (e.target as HTMLInputElement).setCustomValidity(
                localization.get(language).handoff_form.email_error
              );
            }}
            onInput={(e) => {
              const target = e.target as HTMLInputElement;
              target.setCustomValidity("");
              if (!target.validity.valid) {
                target.setCustomValidity(
                  localization.get(language).handoff_form.email_error
                );
              }
            }}
          />
        </div>
        <button
          type="submit"
          className="handoff-form-button"
          style={{ backgroundColor: color }}
          disabled={loading}
        >
          {loading ? (
            <i className="fa-solid fa-spinner fa-spin"></i>
          ) : (
            <>
              <i className="fa-solid fa-paper-plane"></i>{" "}
              {localization.get(language).handoff_form.submit}
            </>
          )}
        </button>
      </form>
    </div>
  );
}
