export default class ChatService {
  public async sendMessage(
    userMessage: any,
    assistantId: any,
    thread_id: any,
    language: any
  ) {
    const url =
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_DENGINE_API_DEV
        : process.env.REACT_APP_DENGINE_API_PROD;

    return await fetch(`${url}`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        drastik_assistant_id: assistantId,
        user_message: userMessage,
        thread_id: thread_id ?? null,
        language: language,
      }),
    });
  }

  public async sendMessageWithoutReply(
    userMessage: any,
    assistantId: any,
    thread_id: any
  ) {
    const url =
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_API_DEV
        : process.env.REACT_APP_API_PROD;

    return await fetch(`${url}chats/sendMessageWithoutReply`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        drastikAssistantId: assistantId,
        message: userMessage,
        threadId: thread_id,
      }),
    });
  }

  public async ping() {
    const url =
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_DENGINE_API_DEV
        : process.env.REACT_APP_DENGINE_API_PROD;

    return await fetch(`${url}/ping`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  public async getWidgetConfiguration(drastikAssistantId: any) {
    const url = `${
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_API_DEV
        : process.env.REACT_APP_API_PROD
    }${
      process.env.REACT_APP_GET_WIDGET_CHAT_CONFIGURATION
    }?drastikAssistantId=${drastikAssistantId}`;

    return await fetch(`${url}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  public async getAssistantChatConfiguration(
    drastikAssistantId: any,
    language: any
  ) {
    const url = `${
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_API_DEV
        : process.env.REACT_APP_API_PROD
    }${
      process.env.REACT_APP_GET_ASSISTANT_CHAT_CONFIGURATION
    }?drastikAssistantId=${drastikAssistantId}&locale=${language}`;

    return await fetch(`${url}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  public getStreamEventSource(
    inputMessage: any,
    assistantId: any,
    threadId: any,
    language: any
  ) {
    let baseUrl =
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_DENGINE_API_DEV
        : process.env.REACT_APP_DENGINE_API_PROD;
    return new EventSource(
      `${baseUrl}/stream?user_message=${inputMessage}&drastik_assistant_id=${assistantId}&thread_id=${threadId}&language=${language}`
    );
  }

  public setRedirectedStatus(data: any) {
    let baseUrl =
      process.env.REACT_APP_IS_PROD !== "yes"
        ? process.env.REACT_APP_API_DEV
        : process.env.REACT_APP_API_PROD;

    let url = baseUrl + (process.env.REACT_APP_SET_REDIRECTED_STATUS ?? "");

    return fetch(`${url}`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
  }
}
