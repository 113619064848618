import { useState, useEffect, useRef } from "react";
import localization from "../../../assets/localization";

const typingSpeed: number = 80;
const pauseDuration: number = 2500;

export default function ChatTooltip({ isMobile, language }: any) {
  const [displayedText, setDisplayedText] = useState<string>("");

  const sentenceIndexRef = useRef<number>(0);

  useEffect(() => {
    let localeSentences = localization.get(language).tooltip_sentences;
    let isMounted = true;

    const runTypingEffect = async () => {
      while (isMounted) {
        const sentence = localeSentences[sentenceIndexRef.current];
        setDisplayedText("");
        const generator = typingGenerator(sentence);

        for (const partialText of generator) {
          if (partialText === "PAUSE") {
            await new Promise((resolve) => setTimeout(resolve, pauseDuration));
            break;
          }
          setDisplayedText(partialText);
          await new Promise((resolve) => setTimeout(resolve, typingSpeed));
        }

        sentenceIndexRef.current =
          (sentenceIndexRef.current + 1) % localeSentences.length;
      }
    };

    runTypingEffect();

    return () => {
      isMounted = false;
    };
  }, [language]);

  function* typingGenerator(sentence: string): Generator<string> {
    let text = "";
    const characters = Array.from(sentence); // Handles emojis as single entities
    for (const char of characters) {
      text += char;
      yield text;
    }
    yield "PAUSE";
  }

  return isMobile ? (
    <div className="chat-tooltip-mobile">
      <div className="chat-tooltip-text-mobile">{displayedText}</div>
    </div>
  ) : (
    <div className="chat-tooltip-desktop">
      <div className="chat-tooltip-text-desktop">{displayedText}</div>
    </div>
  );
}
