import { useEffect, useState } from "react";
import "./App.css";
import Chat from "./widgets/Chat/Chat";

function App({
  assistant,
  isEditor = false,
  editorStyle,
  language,
}: {
  assistant: { id?: string; name?: string };
  isEditor: any;
  editorStyle: any;
  language: any;
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSanitizedLanguage = () => {
    if (!language) {
      return "en";
    }

    if (language.startsWith("en")) {
      return "en";
    }

    if (language.startsWith("it")) {
      return "it";
    }

    return "en";
  };

  useEffect(() => {
    // Load external stylesheets and scripts
    const loadExternalResources = () => {
      const fontAwesomeCdn = document.createElement("link");
      fontAwesomeCdn.rel = "stylesheet";
      fontAwesomeCdn.href =
        "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css";
      fontAwesomeCdn.integrity =
        "sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==";
      fontAwesomeCdn.crossOrigin = "anonymous";
      fontAwesomeCdn.referrerPolicy = "no-referrer";
      document.head.appendChild(fontAwesomeCdn);

      const linkifyJs = document.createElement("script");
      linkifyJs.src =
        "https://cdn.jsdelivr.net/npm/linkifyjs@4.1.3/dist/linkify.min.js";
      document.head.appendChild(linkifyJs);
    };

    loadExternalResources();
  }, []);

  return (
    <div
      className="App"
      style={
        false
          ? {
              height: "100%",
              width: "100%",
            }
          : {}
      }
    >
      <Chat
        startOpen={false}
        assistant={assistant}
        isEditor={isEditor}
        editorStyle={editorStyle}
        isMobile={isMobile}
        language={getSanitizedLanguage()}
      />
    </div>
  );
}

export default App;
